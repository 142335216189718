/* eslint-disable no-unused-vars */
/* --------------------------------------- */
/* STORE CONFIGURATION
/* --------------------------------------- */
const useMagentoCommerce = false; // setup uses magento commerce or community
const storeCode = 'biofarma_id'; // fill it with any store code when the magento is setup with multiple stores. leave it empty to use default store.
const assetsVersion = '1.0.6';
const pwaVersion = '2.5.9';
const HOST = {
    local: 'http://localhost:3000',
    dev: 'https://pwa.biofarma.testingnow.me',
    devbio: 'https://pwa.biofarma.testingnow.me',
    stage: 'https://pwa-staging.digital-healthcare.id',
    prod: 'https://medbiz.id',
};
/* Magento GraphQL Endpoint */
const graphqlEndpoint = {
    local: 'https://biofarma.testingnow.me/graphql',
    dev: 'https://biofarma.testingnow.me/graphql',
    devbio: 'https://biofarma.testingnow.me/graphql',
    stage: 'https://graphql-proxy-staging.digital-healthcare.id/graphql',
    prod: 'https://graphql-proxy.medbiz.id/graphql',
};

/* OMS GraphQL Endpoint */
const OMSGraphqlEndpoint = {
    local: 'https://omsbiofarma.testingnow.me/graphql',
    dev: 'https://omsbiofarma.testingnow.me/graphql',
    stage: 'https://oms-staging.biofarma.sandbox.id/graphql',
    prod: 'https://oms-bo.medbiz.id/graphql',
};

/* --------------------------------------- */
/* FEATURES CONFIGURATION
/* --------------------------------------- */
/* Datadog RUM */
const datadog = {
    version: '1.0.0',
    local: {
        enabled: false,
        applicationId: '',
        clientToken: '',
        site: '',
        service: '',
        env: '',
    },
    dev: {
        enabled: false,
        applicationId: '',
        clientToken: '',
        site: '',
        service: '',
        env: '',
    },
    stage: {
        enabled: false,
        applicationId: '',
        clientToken: '',
        site: '',
        service: '',
        env: '',
    },
    prod: {
        enabled: true,
        applicationId: 'a6082565-203f-4973-a0ab-33255da15f8c',
        clientToken: 'pub4751ebe100d087f57de4b73003174f48',
        site: 'us5.datadoghq.com',
        service: 'pwa-smartpbf-prod',
        env: 'production',
    },
};

/* Password Validator */
const passwordStrength = {
    minValue: 8,
    maxValue: 20,
    numberOfRequiredClass: 3, // Lowercase + Uppercse + Dgits + spesial caracter = 4
};

/* Translation */
const translation = {
    defaultLanguage: 'id', // just change to your default language
    languages: ['id', 'en'], // array code language what you want
    // language label code
    languagesLabel: {
        id: 'ID',
        en: 'EN',
    },
};

/* Google Tag Manager
 * before enable this configuration, firstly you need to import the gtm tags json.
 * gtm tags json need to be exported from Magento admin in Welpixel GTM configuration.
 * adjust the tag name if you want before import into GTM dashboard setting.
 * as reference you can find sample gtm tags in folder "sample/gtm" folder
 * NOTE: this GTM functionality includes connecting to GA via GTM tag.
 */
const GTM = {
    enable: true,
    gtmId: {
        local: '', // sample: GTM-N76V8KQ
        dev: '', // sample: GTM-N76V8KQ
        stage: '', // sample: GTM-N76V8KQ
        prod: 'GTM-KKPLTC5', // sample: GTM-N76V8KQ
    },
};
// error management monitoring
const sentry = {
    enabled: true,
    enableMode: 'production',
    dsn: {
        local: '',
        dev: '',
        stage: 'https://4fa7cacec95c4f159db2d13220f5b6ad@o4504927976554496.ingest.sentry.io/4504927979438080',
        prod: 'https://c7ad3a22cad841ac87c071adabc49fdd@o4504921199411200.ingest.sentry.io/4504921200459776',
    },
};
const rollbar = {
    enabled: false,
    config: {
        accessToken: '76876f52664341b4a1981c4618723bda',
        captureUncaught: true,
        captureUnhandledRejections: true,
    },
};
/* Dashboard */
// identifiers for cmsBlocks in contact page
const cmsSocialMediaLinkIdentifiers = 'pwa_socmed_links';
/* Social media link */
// social media link in dashboard
const enableSocialMediaLink = true;
/* Loader */
const loaderImage = '/assets/img/loader.svg';
/* --------------------------------------- */
/* LOCAD DATA CACHE & COKIES
/* --------------------------------------- */
const expiredCookies = 6;
const storeConfigNameCookie = 'storeConfig';
const nameCartId = 'nci';
const custDataNameCookie = 'cdt';
const nameCheckoutCookie = 'ccdt';
const nameCheckoutState = 'ncs';
const nameGlobalCookie = 'spwa';
const nameToken = 'sk';
const expiredToken = 60 * 60 * 1000;
const expiredDefault = 365;
const localResolverKey = 'resolver';
const keyLocalStorage = {
    home: 'homePageConfig',
};

/* --------------------------------------- */
/* Firebase configuration
/* --------------------------------------- */
function firebaseConfig() {
    let env = 'local';
    if (typeof window !== 'undefined') {
        env = window.APP_ENV;
    } else {
        env = process.env.APP_ENV;
    }
    // eslint-disable-next-line no-unused-vars
    const config = {
        local: {
            apiKey: 'AIzaSyA32GwsjPf-NFDW1gscsnvTQWHL_wNiIhg',
            authDomain: 'biofarma-dev-ce93a.firebaseapp.com',
            projectId: 'biofarma-dev-ce93a',
            storageBucket: 'biofarma-dev-ce93a.appspot.com',
            messagingSenderId: '437466744228',
            appId: '1:437466744228:web:231b0b55f592fe21da2d31',
            measurementId: 'G-PNKVNNCPHE',
        },
        dev: {
            apiKey: 'AIzaSyA32GwsjPf-NFDW1gscsnvTQWHL_wNiIhg',
            authDomain: 'biofarma-dev-ce93a.firebaseapp.com',
            projectId: 'biofarma-dev-ce93a',
            storageBucket: 'biofarma-dev-ce93a.appspot.com',
            messagingSenderId: '437466744228',
            appId: '1:437466744228:web:231b0b55f592fe21da2d31',
            measurementId: 'G-PNKVNNCPHE',
        },
        stage: {
            apiKey: 'AIzaSyDyKn09KAz8872LpArYZ7_WFrqh8ktOw6Y',
            authDomain: 'bf-smartpbf-dgt-qa.firebaseapp.com',
            projectId: 'bf-smartpbf-dgt-qa',
            storageBucket: 'bf-smartpbf-dgt-qa.appspot.com',
            messagingSenderId: '1003503814980',
            appId: '1:1003503814980:web:c25391cee5280b464eaf11',
            measurementId: 'G-C7L1R614KW',
        },
        prod: {
            apiKey: 'AIzaSyCvGbSVAFZh4uFc7NwKwjcCzcLh21Q4Nlk',
            authDomain: 'bf-smartpbf-dgt-prod.firebaseapp.com',
            projectId: 'bf-smartpbf-dgt-prod',
            storageBucket: 'bf-smartpbf-dgt-prod.appspot.com',
            messagingSenderId: '770384439546',
            appId: '1:770384439546:web:29a3a4ef9cfa3248f74143',
            measurementId: 'G-ER0F6R7GWQ',
        },
    };
    return config[env];
}

const features = {
    useCustomStyle: true,
    ssrCache: false,
    magezon: {
        instagramFeed: {
            // eslint-disable-next-line max-len
            urlGraph:
                // eslint-disable-next-line max-len
                'https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=',
        },
        keyStorage: 'mgz_ig_token',
    },
    crm: {
        enabled: false,
        graphqlEndpoint: {
            local: 'http://swiftcrm.testingnow.me/graphql',
            dev: 'http://swiftcrm.testingnow.me/graphql',
            stage: 'http://swiftcrm.testingnow.me/graphql',
            prod: 'http://swiftcrm.testingnow.me/graphql',
        },
    },
    // masuk module -> pindah jika module sudah siap
    productAvailableToCart: {
        SimpleProduct: true,
        ConfigurableProduct: true,
        VirtualProduct: true,
        GroupedProduct: true,
        BundleProduct: true,
        DownloadableProduct: false,
        AwGiftCardProduct: true,
    },
    customInstallApp: {
        enabled: false,
    },
    chatSystem: {
        enable: true,
        autoTerminate: 5, // minutes
        graphqlEndpoint: {
            local: 'https://chat.medbiz.id/graphql',
            dev: 'https://chat.biofarma-p3.testingnow.me/graphql',
            stage: 'https://medbiz-chat-staging.digital-healthcare.id/graphql',
            prod: 'https://chat.medbiz.id/graphql',
        },
    },
    firebase: {
        config: firebaseConfig(),
        pushNotification: {
            enabled: true,
            config: {
                // key from cloud messaging sertificat web push
                pairKey: 'BJ2IqpfQQGrckDUAI7TaX8r0_v6aykBSIIEpZUqhlkzZI2e7WVZk9ZB4xOiWBqTbVo6wk44gnpRLAJDemB66zAU', // sample: BJ2IqpfQQGrckDUAI7TaX8r0_v6aykBSIIEpZUqhlkzZI2e7WVZk9ZB4xOiWBqTbVo6wk44gnpRLAJDemB66zAU
            },
        },
    },
    thumbor: {
        enable: false,
        useHttpsOrHttp: false,
        url: 'https://thumbor.sirclocdn.com/unsafe/widthxheight/filters:format(webp)/',
    },
    globalPromo: {
        key_cookies: 'global_promo_enable',
    },
};
const modules = {
    product: {
        customizableOptions: {
            enabled: true,
            availableOptions: {
                CustomizableAreaOption: true,
                CustomizableDateOption: true,
                CustomizableDropDownOption: true,
                CustomizableMultipleOption: true,
                CustomizableFieldOption: true,
                CustomizableFileOption: false,
                CustomizableRadioOption: true,
                CustomizableCheckboxOption: true,
            },
        },
    },
    authentication: {
        enabled: true,
        path: '/authentication',
    },
    about: {
        enabled: true,
        path: '/about-us',
    },
    blog: {
        enabled: true,
        path: '/blog',
        link: {
            detail: {
                href: '/blog/[id]',
                as: '/blog/',
            },
            category: {
                href: '/blog/category/[id]',
                as: '/blog/category/',
            },
            default: {
                href: '/blog',
            },
        },
        featuredImage: true,
    },
    brands: {
        enabled: true,
        path: '/brands',
    },
    catalog: {
        enabled: true,
        productListing: {
            sort: {
                relevance: true,
                position: true,
                name: true,
                price: true,
                random: true,
                new_old: false,
                new: true,
                bestseller: true,
                onsale: true,
                mostviewed: true,
                wishlisttop: true,
                toprated: true,
                featured: true,
                free: true,
                quantity: true,
            },
        },
    },
    confirmpayment: {
        enabled: true,
        path: '/confirmpayment',
    },
    checkout: {
        enabled: true,
        checkoutOnly: false,
        path: '/checkout',
        ipayUrl: 'ipay88/ipayredirect/?orderId=',
        snapUrl: {
            dev: 'https://app.sandbox.midtrans.com/snap/snap.js',
            prod: 'https://app.midtrans.com/snap/snap.js',
        },
        pickupStore: {
            enabled: false,
        },
        inStorePickup: {
            enabled: false,
        },
        extraFee: {
            enabled: false,
        },
        cashback: {
            enabled: false,
        },
        howtoPay: {
            enabled: true,
        },
        xendit: {
            paymentPrefixCodeOnSuccess: ['alfamart', 'bcava', 'briva', 'bsiva', 'bjbva', 'qris', 'bniva', 'mandiriva', 'permatava', 'indomaret'],
            paymentPrefixCode: ['cc', 'cc_subscription', 'dana', 'ovo', 'linkaja', 'qr_codes', 'dd_bri', 'kredivo'],
        },
    },
    paypal: {
        enabled: true,
        path: '/paypal',
        clientId: {
            local: '', // sample AaOafUT6j2w7kZNb97_cAlI0MjGexmWOaWo650ANV5xgrT3Ff1xtdwJxVByCHUJZ0Ga9HKvbRw5NIjNV
            dev: '',
            prod: '',
            stage: '',
        },
        clientSecret: {
            local: '', // sample EM2Uqt5eiPEgcCMi6NAF94DNsOoP3YBgGRkBGyFQdEXahjzuPfi17sjfhW4N9CfmE_RLjDGuZ19Gf1WP
            dev: '',
            prod: '',
            stage: '',
        },
        intent: 'authorize',
        returnUrl: 'paypal/express/review',
        cancelUrl: 'checkout/cart',
        keyData: 'paypal-data',
        keyToken: 'paypal-token',
        disableFunding: 'venmo%2Cbancontact%2Ceps%2Cgiropay%2Cideal%2Cmybank%2Cp24%2Csofort',
    },
    cart: {
        enabled: true,
        path: '/checkout/cart',
    },
    customer: {
        enabled: true,
        path: '/customer',
        plugin: {
            address: {
                splitCity: true,
            },
            newsletter: {
                enabled: false,
            },
        },
    },
    contact: {
        enabled: true,
        path: '/contact',
    },
    forgotpassword: {
        enabled: true,
        path: '/customer/account/forgotpassword',
    },
    rewardpoint: {
        enabled: true,
        path: '/aw_rewardpoints/info',
    },
    rma: {
        enabled: true,
        path: '/rma/customer',
    },
    storecredit: {
        enabled: false,
        path: '/customer/account/storecredit',
        useCommerceModule: false,
    },
    storeLocator: {
        enabled: true,
        path: '/storelocator',
    },
    giftcard: {
        enabled: false,
        path: '/awgiftcard/card',
        useCommerceModule: false,
    },
    productreview: {
        enabled: true,
        path: '/review/customer',
    },
    login: {
        enabled: true,
        path: '/customer/account/login',
        recaptcha: {
            enabled: false,
        },
    },
    notification: {
        enabled: true,
        path: 'inboxnotification/notification',
    },
    register: {
        enabled: true,
        path: '/company/account/create',
        recaptcha: {
            enabled: true,
        },
    },
    trackingorder: {
        enabled: true,
        path: '/sales/order/track',
        fieldDetail: {
            shipperid: ['name', 'description', 'updateDate'],
            gosend: [
                'bookingType',
                'buyerAddressName',
                'buyerAddressDetail',
                'driverId',
                'driverName',
                'insuranceDetails',
                'liveTrackingUrl',
                'receiverName',
                'sellerAddressDetail',
                'sellerAddressName',
                'status',
                'cancelDescription',
                'orderArrivalTime',
                'orderClosedTime',
                'orderCreatedTime',
            ],
        },
    },
    thanks: {
        enabled: true,
        path: '/checkout/onepage/success',
    },
    home: {
        enabled: true,
    },
    promo: {
        enabled: true,
    },
    productcompare: {
        enabled: false,
    },
    order: {
        enabled: true,
        path: '/sales/order',
    },
    wishlist: {
        enabled: true,
        path: '/wishlist',
    },
    maintenance: {
        enabled: true,
        path: '/maintenance',
    },
    setting: {
        enabled: true,
        path: '/setting',
    },
    error: {
        enabled: true,
    },
};
const nossrCache = [
    '/aw_rewardpoints/info',
    '/sales/order/history',
    '/customer/account/profile',
    '/customer/account/address',
    '/awgiftcard/card',
    '/customer/account/storecredit',
    '/inboxnotification/notification',
    '/customer/newsletter',
    '/rma/customer',
    '/confirmpayment',
    '/checkout',
    '/checkout/cart',
    '/graphql',
    '/authentication',
    '/checkout/onepage/success',
];
const debuging = {
    originalError: false,
};
const general = {
    defaultCurrencyCode: 'IDR',
    defaultCurrencyLocale: 'id-ID',
};
const distributorPortal = {
    local: 'https://pwa-omsbiofarma.testingnow.me/',
    dev: 'https://pwa-omsbiofarma.testingnow.me/',
    staging: 'https://pwa-oms-staging-p3.digital-healthcare.id/',
    prod: 'https://seller.medbiz.id/',
};
module.exports = {
    assetsVersion,
    general,
    sentry,
    storeCode,
    debuging,
    GTM,
    datadog,
    HOST,
    graphqlEndpoint,
    OMSGraphqlEndpoint,
    passwordStrength,
    expiredCookies,
    storeConfigNameCookie,
    nameCartId,
    nameToken,
    expiredToken,
    expiredDefault,
    loaderImage,
    cmsSocialMediaLinkIdentifiers,
    custDataNameCookie,
    nameCheckoutCookie,
    nameCheckoutState,
    nameGlobalCookie,
    enableSocialMediaLink,
    features,
    nossrCache,
    modules,
    localResolverKey,
    useMagentoCommerce,
    rollbar,
    translation,
    keyLocalStorage,
    distributorPortal,
};
